import { sendCommand } from '../tools/backend';
import { Button } from '../components/Button';
import { ButtonContainer } from '../components/ButtonContainer';

export function Audio() {
  return (
    <div id='audio'>
      <ButtonContainer>
        <Button text='Media Play' onClick={() => sendCommand('input', '[MediaPlay]')} />
      </ButtonContainer>

      <ButtonContainer>
        <Button text='PC mic' onClick={() => sendCommand('pc_mic')} />
        <Button text='App Volume Up' onClick={() => sendCommand('adjust_volume', '1')} />
        <Button text='Device Volume Up' onClick={() => sendCommand('adjust_device_volume', '2')} />
        <Button text='Discord audio' onClick={() => sendCommand('discord_audio', '1')} />
        {/* <Button text='Mute Discord' onClick={() => sendCommand('mute_discord')} /> */}
        {/* <Button text='Share Browser' onClick={() => sendCommand('browser_audio_share', '1')} /> */}
      </ButtonContainer>

      <ButtonContainer>
        <Button text='VR mic' onClick={() => sendCommand('vr_mic')} />
        <Button text='App Volume Down' onClick={() => sendCommand('adjust_volume', '-1')} />
        <Button text='Device Volume Down' onClick={() => sendCommand('adjust_device_volume', '-2')} />
        <Button text='Normal audio' onClick={() => sendCommand('discord_audio', '0')} />
        {/* <Button text='Restart Audio' onClick={() => sendCommand('restart_audio')} /> */}
        {/* <Button text='Unshare Browser' onClick={() => sendCommand('browser_audio_share', '0')} /> */}
      </ButtonContainer>
      
      <ButtonContainer>
        <Button text='' onClick={() => { }} />
        <Button text='' onClick={() => { }} />
        <Button text='' onClick={() => { }} />
        <Button text='Streaming Speakers' onClick={() => sendCommand('stream_speakers')} />
      </ButtonContainer>
      
      {/* <ButtonContainer>
        <Button text='Toggle echo' onClick={() => sendCommand('toggle_mic_echo')} />
        <Button text='VR mic gate' onClick={() => sendCommand('toggle_vr_gate')} />
        <Button text='Aux Volume Up' onClick={() => sendCommand('adjust_aux', '1')} />
        <Button text='Share Mic Up' onClick={() => sendCommand('adjust_share', '1')} />
        <Button text='Share Self Up' onClick={() => sendCommand('adjust_self', '1')} />
        <Button text='Aux Volume Down' onClick={() => sendCommand('adjust_aux', '-1')} />
        <Button text='Share Mic Down' onClick={() => sendCommand('adjust_share', '-1')} />
        <Button text='Share Self Down' onClick={() => sendCommand('adjust_self', '-1')} />
      </ButtonContainer> */}
    </div>
  );
}